import React from 'react'
import { graphql } from 'gatsby';
import Layout from "../../../components/Layout";
import "../../../styles/odooServices.scss"
import "../../../styles/services.scss"
import "../../../styles/caseStudy.scss"
import News from '../../../components/News'
import ProductTopSection from '../../../components/ProductTopSelection';
import WhyChooseVisiomateforD365 from "../../../components/WhyChooseVisiomateforD365"
import Wethoughtyoumighthavequestions from '../../../components/Wethoughtyoumighthavequestions'
import CaseStudeiesSimplified from '../../../components/CaseStudeiesSimplified'
import ImageWithTextSection from '../../../components/ImageWithTextSection';

const MicD365XstakIntegration = ({ data }) => {
  return (
    <div className='CaseStudeies'>
      <Layout pageTitle={"Visiomate - Microsoft Dynamics 365 Xstak Integration"}
      descrip = {"Unlock the full potential of your Dynamics 365 with Xstak integration. Gain deeper insights, automate processes, and drive business growth."}>
        <ProductTopSection
          headerData={{
            title: "Visiopack: a complete ERP system best suited for many industries i.e Any Retails, Restaurant, Complex Warehousing etc.  ",
            peragraph: "Indulge in the extraordinary with Visiopack—an Odoo-based solution meticulously crafted exclusively for your business needs. Elevate your operations, streamline efficiency, and experience unparalleled simplicity tailored just for you."
            // , image: headerImage
          }}
          data={data} />
        <WhyChooseVisiomateforD365
          data={{
            title: "Why Choose Visiopack?",
            headings: [
              {
                heading: "Operational Efficiency",
                description:
                  "Visiopack optimizes business processes, automating routine tasks and fostering a more efficient operational environment.",
              },
              {
                heading: "Enhanced Collaboration",
                description:
                  "Visiopack serves as a hub for collaboration, fostering seamless communication and information sharing across different departments.",
              },
              {
                heading: "Optimized Inventory Control",
                description:
                  "Visiopack streamlines inventory management, reducing holding costs, and ensuring timely fulfillment of orders.",
              },
              {
                heading: "Informed Decision-Making",
                description:
                  "Real-time access to accurate data in Visiopack empowers decision-makers, enabling them to make strategic choices that positively impact the organization.",
              },
              {
                heading: "Holistic Data Integration",
                description:
                  "Integration of data across departments in Visiopack provides a comprehensive view of the organization, allowing for better-informed decision-making.",
              },
              {
                heading: "Time and Cost Savings",
                description:
                  "Automation of tasks, such as inventory management and sales reporting, saves time and reduces the need for manual labor.",
              },
            ],
          }}
          dataFormImages={data}
        />
        <ImageWithTextSection
          caseStudyData={{
            data: [
              {
                heading: "Touch Friendly POS Interface for Retailers",
                description:
                  "The touch-friendly interface streamlines transactions, allowing users to easily navigate through menus, select items, and process payments with a simple tap or swipe.",
              },
              {
                heading: "Shop Demand Management",
                description:
                  "Efficient demand management helps businesses maintain optimal inventory levels by aligning stock levels with actual customer demand.",
              },
              {
                heading: "Orders History & Refund ",
                description:
                  "A detailed order history allows customers to track their purchases, view past transactions, and monitor the status of their orders. An efficient refund system ensures that customers receive timely reimbursements, contributing",
              },
              {
                heading: "Multiple Payment Methods",
                description:
                  "Our solution ensures seamless payment experiences by effortlessly configuring multiple payment methods. Embrace the future of payments with ease and versatility.",
              },
              {
                heading: "Dynamic Kitchen Preparation Screen",
                description:
                  "This technology leverages dynamic, real-time information to enhance communication and coordination between the kitchen staff, improving overall kitchen operations.",
              },
              {
                heading: "Order Status Screen for Cusotmers",
                description:
                  "Ensuring that the KOT screen is automatically updated with new orders and reflects any changes made at the Serving front.",
              },
              {
                heading: "Robust Filtration ",
                description:
                  "Offer a seamless data exploration experience with advanced filters for refining data based on parameters like date ranges and categories. Enhance data retrieval with dynamic search functionality and enable complex queries through multi-criteria filtration",
              },
              {
                heading: "Personalized Data Views",
                description:
                  "Tailor the data view to individual preferences through customizable views, allowing users to adjust columns, layout, and arrangement.",
              },
              {
                heading: "Kanban / Card View",
                description:
                  "It provides a clear, real-time overview of tasks, allowing teams to see the status of work at a glance. Users can drag and drop cards between columns, making it easy to track progress and manage tasks in a visual and intuitive way.",
              },
              {
                heading: "List Data View",
                description:
                  "It is a structured and detailed representation that allows users to view and manipulate data in a spreadsheet-like format.",
              },
              {
                heading: "Mobile app for sales overview & purchase processing",
                description:
                  "A detailed order history allows customers to track their purchases, view past transactions, and monitor the status of their orders. An efficient refund system ensures that customers receive timely reimbursements, contributing",
              },
              {
                heading: "Dashboards",
                description:
                  " Graphs, charts, and other visual elements make complex data more comprehensible, enabling users to quickly grasp trends, patterns, and outliers.",
              },
              {
                heading: "Reporting & Analysis",
                description:
                  "Analytics help identify trends and patterns in sales, customer behavior, and market dynamics, allowing businesses to capitalize on opportunities and mitigate challenges.",
              },
            ],
          }}
          dataFormImages={data} />
        <CaseStudeiesSimplified
          TextData={{
            title: 'Simplified Steps to get you Started '
            ,
            Data: [
              {
                peragraph: 'Define Requirements',
                list: [
                  'Clearly outline your business objectives and identify the specific challenges or needs that the software solution should address.',
                  'List down the essential features and functionalities required.'
                ]
              },
              {
                peragraph: 'Demo and Evaluation',
                list: [
                  "Schedule product demonstrations. ",
                  "Evaluate the software's usability, features, and performance. ",
                  "Engage key stakeholders in the evaluation process."
                ]
              },
              {
                peragraph: 'Scalability and Integration',
                list: [
                  "Identify the potential integrations required by the customer.",
                  "Plan the implementation of identified integrations."
                ]
              },
              {
                peragraph: 'UAT & User Feedback',
                list: [
                  "UAT period allows customer to assess the system performance and suitability for the specific business needs.",
                  "Customer’s insights can provide valuable perspectives on usability, functionality mismatch."
                ]
              },
              {
                peragraph: 'User Training',
                list: [
                  " User training is a critical component of successfully implementing the software solution.",
                  "Proper training ensures that users can effectively and efficiently utilize the software, maximizing its benefits."
                ]
              },
              {
                peragraph: 'Post-Implementation Support',
                list: [
                  "Provide ongoing support for users.",
                  "Address any post-implementation issues.",
                  "Evaluate and implement system enhancements based on feedback.",
                ]
              }
            ]
          }} />
        <Wethoughtyoumighthavequestions

          data={[
            {
              question:
                "What is Microsoft Dynamics 365 Finance and Operations? ",
              answer:
                "Microsoft Dynamics 365 Finance and Operations is designed to help organizations streamline and centralize various business processes, including financial management, supply chain management, manufacturing, retail, and human resources. The solution provides a unified platform that integrates these functions, allowing for better collaboration, data visibility, and decision-making across different departments.",
            },
            {
              question:
                "What is Microsoft Dynamics 365 Finance and Operations?",
              answer:
                "Microsoft Dynamics 365 Finance and Operations is designed to help organizations streamline and centralize various business processes, including financial management, supply chain management, manufacturing, retail, and human resources. The solution provides a unified platform that integrates these functions, allowing for better collaboration, data visibility, and decision-making across different departments.",
            },
            {
              question:
                "What is Microsoft Dynamics 365 Finance and Operations?",
              answer:
                "Microsoft Dynamics 365 Finance and Operations is designed to help organizations streamline and centralize various business processes, including financial management, supply chain management, manufacturing, retail, and human resources. The solution provides a unified platform that integrates these functions, allowing for better collaboration, data visibility, and decision-making across different departments.",
            },
            {
              question:
                "What is Microsoft Dynamics 365 Finance and Operations?",
              answer:
                "Microsoft Dynamics 365 Finance and Operations is designed to help organizations streamline and centralize various business processes, including financial management, supply chain management, manufacturing, retail, and human resources. The solution provides a unified platform that integrates these functions, allowing for better collaboration, data visibility, and decision-making across different departments.",
            },
            {
              question:
                "What is Microsoft Dynamics 365 Finance and Operations?",
              answer:
                "Microsoft Dynamics 365 Finance and Operations is designed to help organizations streamline and centralize various business processes, including financial management, supply chain management, manufacturing, retail, and human resources. The solution provides a unified platform that integrates these functions, allowing for better collaboration, data visibility, and decision-making across different departments.",
            },
            {
              question:
                "What is Microsoft Dynamics 365 Finance and Operations?",
              answer:
                "Microsoft Dynamics 365 Finance and Operations is designed to help organizations streamline and centralize various business processes, including financial management, supply chain management, manufacturing, retail, and human resources. The solution provides a unified platform that integrates these functions, allowing for better collaboration, data visibility, and decision-making across different departments.",
            },
          ]}
          title="We thought you might have questions"
        />
        <News isAbout={true} career={true} />
      </Layout>
    </div >
  )
}

export default MicD365XstakIntegration
export const query = graphql`
  query MicD365XstakIntegrationImages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    HeaderImg: file(
      relativePath: { eq: "caseStudies/ERP31.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markFlag: file(relativePath: { eq: "4879902_check_flag_mark_ok_icon_1_copy.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImages: file(relativePath: { eq: "prodbg-01.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img1: file(relativePath: { eq: "caseStudies/Maskgroup9.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img2: file(relativePath: { eq: "caseStudies/Group329.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img3: file(relativePath: { eq: "caseStudies/Group314.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img4: file(relativePath: { eq: "caseStudies/Screenshot2024-01-232121491.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img5: file(relativePath: { eq: "caseStudies/Screenshot2024-01-222140321.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img6: file(relativePath: { eq: "caseStudies/Screenshot2024-01-232238111.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img7: file(relativePath: { eq: "caseStudies/Screenshot2024-01-231916101.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img8: file(relativePath: { eq: "caseStudies/Maskgroup10.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img9: file(relativePath: { eq: "caseStudies/Group314_1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img10: file(relativePath: { eq: "caseStudies/Maskgroup10.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img11: file(relativePath: { eq: "caseStudies/Maskgroup11.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img12: file(relativePath: { eq: "caseStudies/Maskgroup12.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img13: file(relativePath: { eq: "caseStudies/Maskgroup13.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

